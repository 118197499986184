const dateFilter = function (date, format) {
  if (!date) return "";
  date = new Date(date);
  let result;
  const YY = date.getFullYear();
  const MM =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const mm =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const ss =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  switch (format) {
    case "YY-MM-DD hh:mm:ss":
      result = `${YY}-${MM}-${DD} ${hh}:${mm}:${ss}`;
      break;
    case "YY-MM-DD hh:mm":
      result = `${YY}-${MM}-${DD} ${hh}:${mm}`;
      break;
    case "YY/MM/DD hh:mm:ss":
      result = `${YY}/${MM}/${DD} ${hh}:${mm}:${ss}`;
      break;
    default:
      result = `${YY}-${MM}-${DD}`;
  }
  return result;
};

export default dateFilter;
