<template>
  <div>
    <NavMenu />
    <div :class="['content-wrap', { full: !showFooterToolbar }]">
      <router-view />
    </div>
    <FooterToolbar v-if="showFooterToolbar" />
  </div>
</template>
<script>
import NavMenu from "./NavMenu";
import FooterToolbar from "./FooterToolbar";
export default {
  name: "layout",
  data() {
    return {
      showFooterToolbar: true,
    };
  },
  components: {
    NavMenu,
    FooterToolbar,
  },
  watch: {
    $route: {
      handler(value) {
        this.showFooterToolbar = value.path.indexOf("mainBusiness") === -1;
      },
      immediate: true,
    },
  },
};
</script>
