const imgFilter = function (data) {
  let result = data;
  if (data && data.search("http") === -1) {
    if (location.hostname === "localhost") {
      result = "http://122.191.115.212:8084" + data;
    } else {
      result = data;
    }
  }
  return result;
};

export default imgFilter;
