import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { removeToken } from "@/utils/auth";
import router from "@/router/index";

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.config.responseType === "blob") return response;
    if (res.type === "application/octet-stream") return response;
    if (!res.code) return res;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== "S0A00000" && res.code !== "E23MZZ100") {
      if (res.code === "E23-9" || res.code === "-9") {
        // to re-login
        MessageBox.confirm(
          "你的登录已失效，你可以点击取消留在此页面或者点击确定重新登录",
          "确认退出",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            const str = location.hostname.includes(".com")
              ? location.hostname.substring(location.hostname.indexOf("."))
              : location.hostname;
            removeToken({ domain: str }); // must remove  token  first
            setTimeout(() => {
              router.push("/login");
            }, 1000);
          })
          .catch(() => {
            const str = location.hostname.includes(".com")
              ? location.hostname.substring(location.hostname.indexOf("."))
              : location.hostname;
            removeToken({ domain: str });
          });
      } else if (res.code === "E23MZZ12" || res.code === "E23MZZ29") {
        setTimeout(() => {
          router.push("/login");
        }, 1000);
      } else {
        Message({
          message: res.msg || "Error",
          type: "error",
          duration: 5 * 1000,
        });
      }
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    const responseMessage = error.msg || "未知异常，请联系管理员！";
    const message =
      error.msg.indexOf("timeout") > -1
        ? "请求超时，请检查网络后再试！"
        : responseMessage;
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
