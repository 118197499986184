<template>
  <div class="footer-wrap">
    <div class="footer">
      <div class="part2">
        <!-- <div class="sub_company1">子公司</div> -->
        <div class="part2_item">深圳市龙华区引导基金投资管理有限公司</div>
        <div class="part2_item">深圳市龙华区天使投资引导基金有限公司</div>
        <div class="part2_item">深圳市龙华私募股权投资基金管理有限公司</div>
        <div class="part2_item_2">深圳市鹭湖文体旅游发展有限公司</div>
      </div>
      <div class="part2">
        <div class="part2_item">主办：深圳市龙华产业资本投资有限公司</div>
        <div class="part2_item">联系电话：<span>0755 - 23335078</span></div>
        <div class="part2_item">电子邮箱：<span>szlhzb@szlhzb.cn</span></div>
        <div class="part2_item">
          公司地址：深圳市龙华区龙华街道富康行政服务办公区C座6-7楼
        </div>
      </div>
      <div class="part3">
        <img src="../../assets/images/qrcode.png" alt="" />
        <div>扫码关注公众号</div>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="copyright">
        <div>
          {{ copyright }}
          <span
            class="copyright_right"
            @click="openUrl('https://beian.miit.gov.cn/#/Integrated/index')"
            >粤ICP备2021104830号</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footerToolbar",
  data() {
    return {
      copyright: "copyright © 2023深圳市龙华产业资本投资有限公司",
    };
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
