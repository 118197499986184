import request from "@/utils/request";

export function getMenuList(data) {
  return request({
    url: "/portal-rest/lhzb/allcd",
    method: "post",
    data,
  });
}

export function getDataList(data) {
  return request({
    url: "/portal-rest/lhzb/queryMsg",
    method: "post",
    data,
  });
}

export function detailById(data) {
  return request({
    url: "/portal-rest/lhzb/queryMsgDetail",
    method: "post",
    data,
  });
}

export function fundPartner(data) {
  return request({
    url: "/portal-rest/lhzb/fundPartnerList",
    method: "post",
    data,
  });
}

export function getChildCol(data) {
  return request({
    url: "/portal-rest/portal/cols/getChildCol",
    method: "post",
    data,
  });
}

export function majorEventList(data) {
  return request({
    url: "/portal-rest/lhzb/majorEventList",
    method: "post",
    data,
  });
}

export function downloadFileById(data) {
  return request({
    url: "/portal-rest/lhzb/file/download",
    method: "post",
    data,
    responseType: "blob",
  });
}
