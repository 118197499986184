import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/common/Layout";
import getPageTitle from "@/utils/get-page-title";
import { getToken } from "@/utils/auth";
import store from "../store/index";
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/register.vue"),
  },
  {
    path: "/resetpwd",
    name: "resetpwd",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/resetpwd.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/agreement.vue"),
  },
  {
    path: "/",
    component: Layout,
    redirect: "home",
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          authorize: true,
          menuName: "首页",
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
      },
      {
        path: "/detail",
        name: "detail",
        meta: {
          authorize: true,
          menuName: "详情",
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home/detail.vue"),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        meta: {
          authorize: true,
          menuName: "关于我们",
        },
        component: () =>
          import(/* webpackChunkName: "aboutUs" */ "@/views/aboutUs/index.vue"),
      },
      {
        path: "/partyBuilding",
        name: "partyBuilding",
        meta: {
          authorize: true,
          menuName: "企业党建",
        },
        component: () =>
          import(
            /* webpackChunkName: "partyBuilding" */ "@/views/partyBuilding/index.vue"
          ),
      },
      {
        path: "/news",
        name: "news",
        meta: {
          authorize: true,
          menuName: "新闻动态",
        },
        component: () =>
          import(/* webpackChunkName: "news" */ "@/views/news/index.vue"),
      },
      {
        path: "/mainBusiness",
        name: "mainBusiness",
        meta: {
          authorize: true,
          menuName: "主营业务",
        },
        component: () =>
          import(
            /* webpackChunkName: "mainBusiness" */ "@/views/mainBusiness/index.vue"
          ),
      },
      {
        path: "/socialDuty",
        name: "socialDuty",
        meta: {
          authorize: true,
          menuName: "社会职责",
        },
        component: () =>
          import(
            /* webpackChunkName: "socialDuty" */ "@/views/socialDuty/index.vue"
          ),
      },
      {
        path: "/contactUs",
        name: "contactUs",
        meta: {
          authorize: true,
          menuName: "联系我们",
        },
        component: () =>
          import(
            /* webpackChunkName: "contactUs" */ "@/views/contactUs/index.vue"
          ),
      },
      {
        path: "/declare",
        name: "declare",
        meta: {
          authorize: true,
          menuName: "申报入口",
        },
        component: () =>
          import(/* webpackChunkName: "declare" */ "@/views/declare/index.vue"),
      },
    ],
  },
  {
    path: "/subFunds",
    name: "subFunds",
    meta: {
      authorize: true,
      menuName: "子基金申报",
    },
    component: () =>
      import(/* webpackChunkName: "subFunds" */ "@/views/declare/subFunds.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.afterEach(() => {
  // finish progress bar
  window.scrollTo(0, 0);
});
const blackList = ["/subFunds"]; // no redirect blackList

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      const hasGetUserInfo = store.getters.name;
      if (hasGetUserInfo) {
        next();
      } else {
        try {
          // get user info
          // await store.dispatch('user/getInfo')
          // 根据token获取后台菜单权限
          next();
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch("user/resetToken");
          Message.error(error || "Has Error");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/
    if (blackList.indexOf(to.path) === -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});
export default router;
