import dictFilter from "./dictFilter";
import dateFilter from "./dateFilter";
import imgFilter from "./imgFilter";
import delHTMLTag from "./delHTMLTag";
// 过滤器
const filters = {
  dictFilter,
  dateFilter,
  imgFilter,
  delHTMLTag,
};

export default {
  install(Vue) {
    Object.keys(filters).forEach((key) => {
      Vue.filter(key, filters[key]);
    });
  },
};
