const dictFilter = function (keys, list) {
  const keyArr = keys.split(",");
  let value = "";
  list.forEach((item) => {
    if (keyArr.includes(item.key)) {
      value = value + "," + item.value;
    }
  });
  if (value.substr(0, 1) === ",") value = value.substr(1);
  return value;
};

export default dictFilter;
