const delHTMLTag = function (str) {
  str = str.replace(/&nbsp;/g, " ");
  str = str.replace(/&ensp;/g, " ");
  str = str.replace(/&emsp;/g, " ");
  str = str.replace(/&bull;/g, " • ");
  str = str.replace(/&ZeroWidthSpace;/g, " ");
  str = str.replace(/&amp;/g, "&");
  str = str.replace(/&gt;/g, ">");
  str = str.replace(/&lt;/g, "<");
  str = str.replace(/<style[\s\S]*?<\/style>/g, "");
  str = str.replace(/<script[^>]*?>[\s\S]*?<\/script>/g, "");
  // str = str.replace(/<(.|\n)+?>/g, '')
  str = str.replace(/<!--\/?.+?\/?-->/g, "");
  str = str.replace(/<\/?.+?\/?>/g, "");
  return str.replace(/<[^>]+>/g, "");
};

export default delHTMLTag;
