<template>
  <div class="menu-wrap">
    <div class="menu-box">
      <div class="logo">
        <img @click="toHome" src="@/assets/images/logo.png" />
      </div>
      <div class="menu-right">
        <div id="menu_index">
          <!-- web端菜单导航 -->
          <ul class="menu_index_pc" v-if="seen">
            <li
              v-for="(item, i) in menuList"
              :key="i"
              :class="
                currentMenuName.indexOf(item.path) !== -1
                  ? 'menu_pc_list_item active'
                  : 'menu_pc_list_item'
              "
              @click="toPath(item)"
            >
              <div
                :class="
                  item.colCode === 'ZJJSB'
                    ? 'declare menu_pc_item'
                    : 'menu_pc_item'
                "
              >
                {{ item.menuName }}
              </div>
              <div
                class="menu_pc_subitem"
                v-if="item.chrilden && item.chrilden.length"
              >
                <div
                  :class="
                    currentMenuName.indexOf(subItem.colCode) !== -1 ||
                    currentMenuName.indexOf(subItem.path) !== -1
                      ? 'subitem active'
                      : 'subitem'
                  "
                  v-for="(subItem, index) in item.chrilden"
                  :key="index"
                  @click.stop="toPath(subItem)"
                >
                  {{ subItem.menuName }}
                </div>
              </div>
            </li>
          </ul>
          <!-- <el-menu
            v-if="seen"
            :default-active="activeIndex"
            class="el-menu-demo"
            menu-trigger="hover"
            text-color="#333"
            active-text-color="#409eff"
            style="height: 65px; border: 0"
            mode="horizontal"
            @click="handleSelect"
          >
            <template v-for="(item, i) in menuList">
              <el-menu-item
                v-if="!(item.chrilden && item.chrilden.length)"
                :index="String(i + 1)"
                :class="
                  activeIndex === String(i + 1)
                    ? 'header-title is-active'
                    : 'header-title'
                "
                :key="i"
              >
                <template slot="title"
                  ><div :class="item.colCode === 'ZJJSB' ? 'declare' : ''">
                    <span @click="toPath(item)">{{ item.menuName }}</span>
                  </div>
                </template>
              </el-menu-item>
              <el-submenu
                :index="String(i + 1)"
                :key="i"
                :class="
                  activeIndex === String(i + 1)
                    ? 'header-title is-active'
                    : 'header-title'
                "
                v-else
              >
                <template v-if="item.colCode === 'ZJJSB'" slot="title"
                  ><div :class="item.colCode === 'ZJJSB' ? 'declare' : ''">
                    <span @click="toPath(item)">{{ item.menuName }}</span>
                  </div>
                </template>
                <template v-else slot="title">
                  <div @click="toPath(item)">
                    {{ item.menuName }}
                  </div>
                </template>
                <el-menu-item
                  :index="i + 1 + '-' + sindex + 1"
                  v-for="(subItem, sindex) in item.chrilden"
                  :key="sindex"
                  @click="toPath(subItem)"
                  >{{ subItem.menuName }}</el-menu-item
                >
              </el-submenu>
            </template>
          </el-menu> -->
          <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
          <div v-if="!seen">
            <div class="menu_mobile" @click="showMenu">
              <img src="../../assets/images/菜单.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ul class="menu_mobile_list" v-if="isShowMenu">
        <li
          :class="
            currentMenuName.indexOf(item.path) !== -1
              ? 'menu_mobile_list_item active'
              : 'menu_mobile_list_item'
          "
          v-for="(item, i) in menuList"
          :key="i"
          @click="toPath(item)"
        >
          {{ item.menuName }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getMenuList } from "@/api/common";
export default {
  name: "NavMenu",
  data() {
    return {
      activeIndex: "1",
      isSearch: false,
      menuList: [],
      seen: true,
      currentMenu: "首页",
      isShowMenu: false,
      currentMenuName: location.href,
    };
  },
  computed: {
    // menuList() {
    //   return this.$store.getters["user/menuList"] || [];
    // },
  },
  watch: {
    $route: {
      handler(value) {
        this.menuList.map((item, i) => {
          if (item.chrilden && item.chrilden.length) {
            item.chrilden.map((item2) => {
              if (item2.path.indexOf(value.path) !== -1) {
                this.activeIndex = String(i + 1);
                return;
              }
            });
          } else {
            if (item.path.indexOf(value.path) !== -1) {
              this.activeIndex = String(i + 1);
              return;
            }
          }
        });
        this.currentMenuName = location.href;
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (
        navigator.userAgent.match(/Mobi/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        this.seen = false;
      }
      getMenuList({}).then((res) => {
        // console.log(res);
        // res.data.moduleCulumnDTOList = [
        //   {
        //     id: 32,
        //     colCode: "SY",
        //     colName: "首页",
        //     parentCode: "0",
        //     colOrder: "10",
        //     pageType: null,
        //     frontAddress: "/home",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "1",
        //     childList: [],
        //   },
        //   {
        //     id: 5,
        //     colCode: "GYWM",
        //     colName: "关于我们",
        //     parentCode: "0",
        //     colOrder: "20",
        //     pageType: null,
        //     frontAddress: "/aboutUs",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "1",
        //     childList: [
        //       {
        //         id: 6,
        //         colCode: "GSJJ",
        //         colName: "公司简介",
        //         parentCode: "GYWM",
        //         colOrder: "1",
        //         pageType: "3",
        //         frontAddress: "/aboutUs?id=1",
        //         parentName: "关于我们",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 7,
        //         colCode: "DSZZC",
        //         colName: "董事长致辞",
        //         parentCode: "GYWM",
        //         colOrder: "2",
        //         pageType: "3",
        //         frontAddress: "/aboutUs?id=2",
        //         parentName: "关于我们",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 8,
        //         colCode: "GLTD",
        //         colName: "管理团队",
        //         parentCode: "GYWM",
        //         colOrder: "3",
        //         pageType: "3",
        //         frontAddress: "/aboutUs?id=3",
        //         parentName: "关于我们",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 9,
        //         colCode: "ZZJG",
        //         colName: "组织架构",
        //         parentCode: "GYWM",
        //         colOrder: "4",
        //         pageType: "3",
        //         frontAddress: "/aboutUs?id=4",
        //         parentName: "关于我们",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 33,
        //         colCode: "GSDSJ",
        //         colName: "公司大事件",
        //         parentCode: "GYWM",
        //         colOrder: "5",
        //         pageType: "3",
        //         frontAddress: "/aboutUs?id=5",
        //         parentName: "关于我们",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 34,
        //         colCode: "QYXCP",
        //         colName: "企业宣传片",
        //         parentCode: "GYWM",
        //         colOrder: "6",
        //         pageType: "3",
        //         frontAddress: "/aboutUs?id=6",
        //         parentName: "关于我们",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 10,
        //         colCode: "ZGS",
        //         colName: "子公司",
        //         parentCode: "GYWM",
        //         colOrder: "7",
        //         pageType: "3",
        //         frontAddress: "/aboutUs?id=7",
        //         parentName: "关于我们",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //     ],
        //   },
        //   {
        //     id: 23,
        //     colCode: "GQDJ",
        //     colName: "国企党建",
        //     parentCode: "0",
        //     colOrder: "20",
        //     pageType: null,
        //     frontAddress: "/partyBuilding",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "1",
        //     childList: [
        //       {
        //         id: 24,
        //         colCode: "DJGZ",
        //         colName: "党建工作",
        //         parentCode: "GQDJ",
        //         colOrder: "1",
        //         pageType: "1",
        //         frontAddress: "/partyBuilding?id=1",
        //         parentName: "国企党建",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 25,
        //         colCode: "DSXX",
        //         colName: "党史学习",
        //         parentCode: "GQDJ",
        //         colOrder: "2",
        //         pageType: "1",
        //         frontAddress: "/partyBuilding?id=2",
        //         parentName: "国企党建",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 26,
        //         colCode: "FKNK",
        //         colName: "风控内控",
        //         parentCode: "GQDJ",
        //         colOrder: "3",
        //         pageType: "3",
        //         frontAddress: "/partyBuilding?id=3",
        //         parentName: "国企党建",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 35,
        //         colCode: "XFGZ",
        //         colName: "信访工作",
        //         parentCode: "GQDJ",
        //         colOrder: "4",
        //         pageType: "1",
        //         frontAddress: "/partyBuilding?id=4",
        //         parentName: "国企党建",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 36,
        //         colCode: "TJFC",
        //         colName: "团建风采",
        //         parentCode: "GQDJ",
        //         colOrder: "5",
        //         pageType: "1",
        //         frontAddress: "/partyBuilding?id=5",
        //         parentName: "国企党建",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //     ],
        //   },
        //   {
        //     id: 19,
        //     colCode: "XWDT",
        //     colName: "新闻动态",
        //     parentCode: "0",
        //     colOrder: "30",
        //     pageType: null,
        //     frontAddress: "/news",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "1",
        //     childList: [
        //       {
        //         id: 20,
        //         colCode: "GSXW",
        //         colName: "公司新闻",
        //         parentCode: "XWDT",
        //         colOrder: "1",
        //         pageType: "1",
        //         frontAddress: "/news?id=1",
        //         parentName: "新闻动态",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 21,
        //         colCode: "TZGG",
        //         colName: "通知公告",
        //         parentCode: "XWDT",
        //         colOrder: "2",
        //         pageType: "1",
        //         frontAddress: "/news?id=2",
        //         parentName: "新闻动态",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //     ],
        //   },
        //   {
        //     id: 11,
        //     colCode: "ZYYW",
        //     colName: "主营业务",
        //     parentCode: "0",
        //     colOrder: "40",
        //     pageType: null,
        //     frontAddress: "/mainBusiness",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "0",
        //     childList: [
        //       {
        //         id: 12,
        //         colCode: "JJGL",
        //         colName: "基金管理",
        //         parentCode: "ZYYW",
        //         colOrder: "1",
        //         pageType: null,
        //         frontAddress: "/mainBusiness?id=1",
        //         parentName: "主营业务",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 13,
        //         colCode: "ZLZT",
        //         colName: "战略直投",
        //         parentCode: "ZYYW",
        //         colOrder: "2",
        //         pageType: null,
        //         frontAddress: "/mainBusiness?id=2",
        //         parentName: "主营业务",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 14,
        //         colCode: "ZHJR",
        //         colName: "综合金融",
        //         parentCode: "ZYYW",
        //         colOrder: "3",
        //         pageType: null,
        //         frontAddress: "/mainBusiness?id=3",
        //         parentName: "主营业务",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 15,
        //         colCode: "CYFW",
        //         colName: "产业服务",
        //         parentCode: "ZYYW",
        //         colOrder: "4",
        //         pageType: null,
        //         frontAddress: "/mainBusiness?id=4",
        //         parentName: "主营业务",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //     ],
        //   },
        //   {
        //     id: 16,
        //     colCode: "SHZR",
        //     colName: "社会责任",
        //     parentCode: "0",
        //     colOrder: "50",
        //     pageType: null,
        //     frontAddress: "/socialDuty",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "1",
        //     childList: [
        //       {
        //         id: 17,
        //         colCode: "QYWH",
        //         colName: "企业文化",
        //         parentCode: "SHZR",
        //         colOrder: "1",
        //         pageType: null,
        //         frontAddress: "/socialDuty?id=1",
        //         parentName: "社会责任",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //       {
        //         id: 18,
        //         colCode: "XCZX",
        //         colName: "乡村振兴",
        //         parentCode: "SHZR",
        //         colOrder: "2",
        //         pageType: null,
        //         frontAddress: "/socialDuty?id=2",
        //         parentName: "社会责任",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //     ],
        //   },
        //   {
        //     id: 27,
        //     colCode: "ZJJSB",
        //     colName: "子基金申报",
        //     parentCode: "0",
        //     colOrder: "70",
        //     pageType: null,
        //     frontAddress: "/declare",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "1",
        //     childList: [
        //       {
        //         id: 28,
        //         colCode: "SBZN",
        //         colName: "申报指南",
        //         parentCode: "ZJJSB",
        //         colOrder: "1",
        //         pageType: "1",
        //         frontAddress: "/declare?id=1",
        //         parentName: "子基金申报",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 39,
        //         colCode: "LXCX",
        //         colName: "遴选程序",
        //         parentCode: "ZJJSB",
        //         colOrder: "2",
        //         pageType: "1",
        //         frontAddress: "/declare?id=2",
        //         parentName: "子基金申报",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 40,
        //         colCode: "SBFS",
        //         colName: "申报方式",
        //         parentCode: "ZJJSB",
        //         colOrder: "3",
        //         pageType: "2",
        //         frontAddress: "/declare?id=3",
        //         parentName: "子基金申报",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 41,
        //         colCode: "FJXZ",
        //         colName: "附件下载",
        //         parentCode: "ZJJSB",
        //         colOrder: "4",
        //         pageType: "1",
        //         frontAddress: "/declare?id=4",
        //         parentName: "子基金申报",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //       {
        //         id: 42,
        //         colCode: "QYSB",
        //         colName: "企业申报",
        //         parentCode: "ZJJSB",
        //         colOrder: "5",
        //         pageType: "3",
        //         frontAddress: "/declare?id=5",
        //         parentName: "子基金申报",
        //         isfrontCol: "1",
        //         isbackCol: "1",
        //         childList: null,
        //       },
        //     ],
        //   },
        //   {
        //     id: 30,
        //     colCode: "LXWM",
        //     colName: "联系我们",
        //     parentCode: "0",
        //     colOrder: "80",
        //     pageType: null,
        //     frontAddress: "/contactUs",
        //     parentName: null,
        //     isfrontCol: "1",
        //     isbackCol: "0",
        //     childList: [
        //       {
        //         id: 31,
        //         colCode: "LXFS",
        //         colName: "联系方式",
        //         parentCode: "LXWM",
        //         colOrder: "1",
        //         pageType: null,
        //         frontAddress: "/aboutUs",
        //         parentName: "联系我们",
        //         isfrontCol: "1",
        //         isbackCol: "0",
        //         childList: null,
        //       },
        //     ],
        //   },
        // ];
        this.menuList = this.getTreeData(res.data.moduleCulumnDTOList);
        this.menuList = this.menuList.map((item) => {
          if (item.colCode === "ZJJSB") {
            item = {
              menuName: item.menuName,
              path: item.path,
              colCode: item.colCode,
              chrilden: [
                {
                  menuName: "龙华区天使基金",
                  path: "/declare?type=angel",
                  id: 1,
                },
                {
                  menuName: "龙华区引导基金",
                  path: "/declare?type=guidance",
                  id: 2,
                },
              ],
            };
          }
          return item;
        });
      });
    },
    handleSelect(val) {
      console.log(val);
    },
    toPath(item) {
      this.$router.push({ path: item.path, query: {} }, () => {});
      this.isShowMenu = false;
    },
    showSearch() {
      this.isSearch = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    toHome() {},
    getTreeData(data) {
      if (!data || data.length < 1) {
        return;
      } else {
        for (var i in data) {
          data[i].menuName = data[i].colName;
          data[i].chrilden = data[i].childList;
          data[i].path = data[i].frontAddress;
          if (data[i].childList && data[i].childList.length > 0) {
            this.getTreeData(data[i].childList);
          } else {
            data[i].children = undefined;
          }
        }
      }
      return data;
    },
    logOut() {
      console.log("退出");
    },
    showMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
<style scoped>
.el-menu-item {
  font-size: 18px !important;
}
</style>
<style lang="less" scoped>
/deep/ .el-submenu {
  .el-submenu__icon-arrow {
    color: #fff;
  }
  &:last-child {
    .el-submenu__icon-arrow {
      color: #fff;
    }
  }
}
/deep/ .el-menu--popup .el-menu-item {
  background: rgba(64, 158, 255, 0.2);
}
/deep/ .el-menu-item.is-active {
  color: #409eff !important;
}
/deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #409eff !important;
  border-bottom: 2px solid #409eff !important;
}
/deep/ .el-submenu__title {
  font-size: 18px;
}
</style>
